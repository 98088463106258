import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.section``

const List = styled.ul`
  li:first-child {
    opacity: 0.4;
  }

  @media ${(props) => props.theme.xsmallDown} {
    b,
    span {
      display: block;
    }

    span {
      font-size: ${rem(14)};
      margin: 0.4em 0;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`

const Highlights = ({ data }) => {
  if (!data || !data.length) return null

  return (
    <Container>
      <Grid>
        <Row>
          <Cell size={23}>
            <List>
              {data.map((highlight, i) => (
                <li key={i} className="styled-h1">
                  <b>{highlight.text}</b>
                  {` `}
                  <span>{highlight.text2}</span>
                </li>
              ))}
            </List>
          </Cell>
        </Row>
      </Grid>
    </Container>
  )
}

Highlights.propTypes = {
  data: PropTypes.array,
}

export default Highlights
