import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import Intro from "../components/intro"
import Numbers from "../components/numbers"
import TextSections from "../components/text-sections"
import Highlights from "../components/page-product/highlights"

const UseCasePage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    hero,
    intro,
    textSections,
    useCasesNumbers,
    highlights,
    siteMessage,
  } = data
  const { translations, menus } = pageContext

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />

      <Hero data={hero} />

      <Intro data={intro} />

      <TextSections data={textSections} />

      <Numbers data={useCasesNumbers} />

      <Highlights data={highlights.acf.highlights} />
    </Layout>
  )
}

UseCasePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default UseCasePage

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment

    ...HeroUseCaseFragment
    ...IntroUseCaseFragment
    ...HideFooterSectionsUseCaseFragment
    ...TextSectionsUseCaseFragment
    ...UseCasesNumbersPageFragment
    ...HighlightsUseCaseFragment
  }
`
